import React, { useState, useRef } from "react";
import Footer from "../Components/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import "./Pages.css";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import ReactLoading from "react-loading";
import trim from 'lodash/trim';
import { useNavigate } from "react-router-dom";

function MainRegister() {
  const [formData, setFormData] = useState({
    name: "",
    student_no: "",
    branch: "",
    email_id: "",
    phone_no: "",
    username: "",
    residence: "",
    section: "",
    recaptchaToken: ""
  });

  const navigate = useNavigate();
  const captchaRef = useRef();
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRecaptchaChange = (value) => {
    setFormData({ ...formData, recaptchaToken: value.trim() });
  };

  const validateForm = () => {
    const { name, student_no, branch, email_id, phone_no, username, residence, section, recaptchaToken } = formData;

    const errors = {};
    const allFieldsEmpty = [name, student_no, branch, email_id, phone_no, username, residence, section, recaptchaToken].every(field => !field.trim());

    if (allFieldsEmpty) {
      errors.allFields = "All fields are required";
    } else {
      if (!recaptchaToken.trim()) {
        errors.captcha = "Please complete the reCAPTCHA";
      }
      if (!name.trim()) {
        errors.name = "Name is required";
      } else if (/\d/.test(name)) {
        errors.name = "Name cannot contain numbers";
      }

      if (!student_no.trim()) {
        errors.student_no = "Student ID is required";
      } else if (!/^23\d{5,}$/.test(student_no)) {
        errors.student_no = "Student ID is invalid";
      }

      if (!branch.trim()) {
        errors.branch = "Branch is required";
      }

      if (!email_id.trim()) {
        errors.email_id = "Email is required";
      } else if (!/^[a-zA-Z0-9]+@akgec\.ac\.in$/.test(email_id)) {
        errors.email_id = "Enter your college email id";
      }

      if (!phone_no.trim()) {
        errors.phone_no = "Phone number is required";
      } else if (!/^\d{10}$/.test(phone_no)) {
        errors.phone_no = "Phone number should be 10 digits";
      }

      if (!username.trim()) {
        errors.username = "Username is required";
      }

      if (!residence.trim()) {
        errors.residence = "Residence is required";
      }

      if (!section.trim()) {
        errors.section = "Section is required";
      }
    }

    return errors;
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    captchaRef.current.reset();

    try {
      const errors = validateForm();
      if (Object.keys(errors).length === 0) {
        // If no errors, submit the form
        // console.log(formData);
        // Uncomment the axios call to enable form submission
        const response = await axios.post(
          process.env.REACT_APP_API,
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        // console.log(response.data);
        navigate('/register', { state: { formData } });
        toast.success("Form submitted successfully!");
      } else {
        // Display errors as toast notifications
        const firstError = Object.values(errors)[0];
        toast.error(firstError);
        // console.log("Validation errors:", errors);
      }
      setLoading(true);
    } catch (error) {
      toast.error("Submission failed", error);
      // console.error("Error submitting registration:", error);
    } finally {
      setFormData({ ...formData, captcha: "" });
      setLoading(false);
    }
  };

  return (
    <div className="overflow-x-hidden">
      <Toaster />
      <div className="Header"></div>
      <div className="mx-[4vw] xs:flex xs:justify-center">
        <div className="bg-[#231F4F] bg-opacity-70 rounded-2xl mt-[8vh] px-3 py-5">
          <h1 className="m-2 text-5xl font-normal text-center text-white h1_name_RegisterPage">
            Register
          </h1>
          <form onSubmit={submitHandler}>
            <div className="flex justify-around my-10 Register_form md:gap-[10vw] items-top">
              <section className="Left_register mx-2 flex flex-col w-[25rem]">
                <label className="text-white">Name:</label>
                <input
                  type="text"
                  className="h-[2rem] rounded pl-2 "
                  name="name"
                  value={formData.name}
                  onChange={onChange}
                />
                <br />

                <label className="text-white">HackerRank Username:</label>
                <input
                  name="username"
                  className="h-[2rem] rounded pl-2"
                  value={formData.username}
                  onChange={onChange}
                />
                <br />

                <label className="text-white">Student Number:</label>
                <input
                  type="number"
                  name="student_no"
                  className="h-[2rem] rounded pl-2"
                  value={formData.student_no}
                  onChange={onChange}
                />
                <br />

                <label className="text-white">Residence:</label>
                <select
                  name="residence"
                  className="h-[2rem] rounded pl-2"
                  value={formData.residence}
                  onChange={onChange}
                >
                  <option value="select">Select</option>
                  <option value="Hosteller">Hostler</option>
                  <option value="DayScholar">DayScholar</option>
                </select>
                <br />
              </section>

              <section className="Right_register mx-3 flex flex-col m-0 w-[25rem]">
                <label className="text-white">Branch:</label>
                <select
                  name="branch"
                  className="h-[2rem] rounded pl-2"
                  value={formData.branch}
                  onChange={onChange}
                >
                  <option value="select">Select</option>
                  <option value="CS">CS</option>
                  <option value="CSE">CSE</option>
                  <option value="CSE-AIML">CSE-AIML</option>
                  <option value="CSE-DS">CSE-DS</option>
                  <option value="CSE-HINDI">CSE-Hindi</option>
                  <option value="AIML">AI-ML</option>
                  <option value="CSIT">CS/IT</option>
                  <option value="IT">IT</option>
                  <option value="EN">EN</option>
                  <option value="ECE">ECE</option>
                  <option value="MECH">Mechanical</option>
                  <option value="CIVIL">CIVIL</option>
                </select>
                <br />

                <label className="text-white">Section:</label>
                <select
                  name="section"
                  className="h-[2rem] rounded pl-2"
                  value={formData.section}
                  onChange={onChange}
                >
                  <option value="select">Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
                <br />

                <label className="text-white">Phone Number:</label>
                <input
                  type="number"
                  name="phone_no"
                  className="h-[2rem] rounded pl-2"
                  value={formData.phone_no}
                  onChange={onChange}
                />
                <br />

                <label className="text-white">Email Address:</label>
                <input
                  type="string"
                  name="email_id"
                  className="h-[2rem] rounded pl-2"
                  value={formData.email_id}
                  onChange={onChange}
                />
                <br />

                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_KEY_CHECKBOX}
                  onChange={handleRecaptchaChange}
                  className="lg:scale-[1] scale-[0.8] lg:ml-0 self-center sm:self-start sm:-ml-8"
                  ref={captchaRef}
                  required
                />
              </section>
            </div>

            <div className="flex justify-center">
              <button className="Submit_Button bg-[#E5B4FF] w-[200px] h-[60px] rounded-lg text-xl">
                {loading ? <ReactLoading type="spinningBubbles" color="#000" height={35} width={35} className="ml-20 loader" /> : "Register Now!"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default MainRegister;