import React, { useState } from "react";
import logo from "../assets/ccc logo.svg";
import { Link } from "react-router-dom"; // Importing icons
import { HiMenu } from 'react-icons/hi'; // Menu icon for mobile
import { RxCross1 } from "react-icons/rx";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="pt-10">
      <div className="flex items-center justify-between px-4 navbar-container md:px-8">
        <div className="ccc-logo ">
          <img src={logo} alt="Logo" className="h-12" />
        </div>
        <div className="items-center flex-grow hidden text-white md:flex md:justify-around">
          <Link to="/" className="flex items-center link">
             Home
          </Link>
          <Link to="/about" className="flex items-center link">
             About
          </Link>
          {/* <Link to="/faculty" className="flex items-center link">
             Faculty
          </Link> */}
          <Link to="/register" className="flex items-center link">
             Register
          </Link>
        </div>
        <button
          className="text-white md:hidden focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen? (<RxCross1 className="w-8 h-8" />) : (<HiMenu className="w-8 h-8" />)}
        </button>
      </div>

      {/* Mobile menu */}
      <div className={`md:hidden ${isOpen ? "block" : "hidden"} bg-none pt-7 text-center text-xl text-white`}>
        <Link onClick={()=> setIsOpen(false)} to="/" className="block px-4 py-2 active:bg-[#E5B4FF] items-center">
          Home
        </Link>
        <Link onClick={()=> setIsOpen(false)} to="/about" className="block px-4 py-3 active:bg-[#E5B4FF] items-center">
           About
        </Link>
        <Link onClick={()=> setIsOpen(false)} to="/mainregister" className="block px-4 py-2 active:bg-[#E5B4FF] items-center">
          Register
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
