import React from "react";
import Tech from "../assets/Tech1.svg";
import Rishabh from "../assets/Rectangle 29.svg";
import Sarthak from "../assets/Sarthak Srivastava 1.svg";
import Priya from "../assets/20221127_183841 1.svg";
import location from "../assets/Vector(1).svg";
import akgec from "../assets/Group 41.svg";
import call from "../assets/Vector(2).svg";
import Recruitment from "../assets/Recruitment.svg"
import Akshat from "../assets/Akshat.jpeg"
import Kritika from "../assets/Kritika.jpeg"
import Manoj from "../assets/Manoj.png"
import { CiLocationOn } from "react-icons/ci";
import Raghav from "../assets/Raghav.svg"
import Preeti from "../assets/Preeti.svg"
import Santosh from "../assets/Sir.jpg"

const Footer = () => {
  return (
   

    <div className="mt-16 border-t-2 ">

     <div className="flex flex-col items-center w-full ml-0 gap-[1.5vw] lg:justify-between height-lg:-ml-7 md:flex-row">

    <div className="   lg:w-[45vw]"> <img src={Recruitment} alt="ImageNA" className="max-w-full mt-5 md:ml-11 w-[35vw] lg:w-[40vw]"/></div>
    <div className=" md:w-[45vw] ml-5 self-center sm:self-start mt-6 flex justify-evenly flex-nowrap gap-6 text-[#fff]">

        <div className=" flex flex-col items-center gap-2 bg-[#231F4F] bg w-[25vw] sm:w-auto p-4 rounded-xl    h-full "> <div className=""> <img src={Santosh} className=""></img></div> <div className="text-xs  text-center "> <p className=" text-[1.5vh]  sm:text-sm">Mr. Santosh Mishra</p> <p>(Faculty Coordinator)</p></div></div>
        <div className=" flex flex-col items-center gap-2 bg-[#231F4F] w-[25vw] sm:w-auto  p-4  rounded-xl     md:h-full"> <div> <img src={Raghav} className=""></img></div> <div className="text-xs text-center"> <p className="text-[1.5vh] sm:text-sm">Raghav Aggarwal </p> <p>(Head Coordinator)</p></div></div>
        <div className=" flex flex-col mr-5 items-center gap-2 bg-[#231F4F] w-[25vw] sm:w-auto  p-4 rounded-xl md:h-full"> <div> <img src={Preeti} className=""></img></div> <div className="text-xs text-center "> <p className="text-[1.5vh] sm:text-sm  "> Priti  Kapoor</p><p>(Head Coordinator)</p></div></div>

        </div>
     </div>
      
      <div className=" flex flex-col justify-between md:flex-row mt-6 gap-3 text-[#fff]">
        <div className="flex flex-col gap-2 lg:w-auto w-[90vw] md:w-[65vw] md:ml-11">
          <div className="flex items-center gap-2 flex-nowrap"><img src={akgec}></img> <p className=" text-[0.7rem]">Ajay Kumar Garg Engineering College, 27th Km milestone NH-24 Delhi-Hapur Bypass, Adhyatmik Nagar Ghaziabad.</p></div>
          <div className="flex items-center gap-2 flex-nowrap"> <CiLocationOn className="text-3xl "/> <p className=" text-[0.7rem]">If you require help or have any inquiries, feel free to reach out - we're here to support you. CCC Lab, 3th floor CSIT Block from 4pm onwards</p></div>
        </div>
       
         <div className=" flex flex-col text-[0.8rem] md:mr-16  mt-6 md:mt-0 text-[#fff]">
          <div> 
            <h2>Event Coordinators</h2>
          </div>
          <div className=" flex flex-col ">
          <p>Akshat  +91 9267902097</p>
            <p>Manoj +91 8433428790</p>
      
           </div>
         </div>
        
      </div>


    </div>
  );
};

export default Footer;