import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Faculty from './Pages/Faculty';
import Register from './Pages/Register';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import MainRegister from './Pages/MainRegister';
import './App.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Toaster position="top-center" reverseOrder={false} />
    <div className="flex flex-col App">

      <div className=''><Navbar /></div>

      <div className='p-6 overflow-y-auto content'>

        <Routes>

          <Route path="/" element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='/faculty' element={<Faculty />} />
          <Route path='/register' element={<Register />} />
          <Route path='footer' element={<Footer />} />
          <Route path='/mainregister' element={<MainRegister />} />

        </Routes>
      </div>

    </div>
  </BrowserRouter>
);


reportWebVitals();
