import React from "react";
import Footer from "../Components/Footer";
import Tech from "../assets/Group 30(1).svg";
import Rohit from "../assets/Ellipse 40.svg";
import location from "../assets/Vector(1).svg";
import calender from "../assets/Vector(3).svg";
import { useNavigate } from "react-router-dom";
import Spocc from "../assets/SPOCC’24.svg";
import cross from "../assets/cross.svg";
import CCC from "../assets/CCC image.svg";
import { SlCalender } from "react-icons/sl";
import { FaRegClock } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import TopCross from "../assets/TopCross.png"
import green from "../assets/Green.png"
import blue from "../assets/Blue.png"
import second from "../assets/2.png"
import third from "../assets/3.png"
import fourth from "../assets/4.png"
import fifth from "../assets/5.png"
// import "./Pages.css";

const Home = () => {
  const navigate = useNavigate();

  function clickHandler() {
    navigate("/register");
  }

  return (
    <div className="flex flex-col ">
   
      <div className=" text-[#fff] flex flex-col relative">
              
              <div className="">

        <div className="flex flex-col flex-wrap items-center justify-center gap-6 mt-6  md:justify-evenly lg:flex-row md:gap-0">
          <div className="md:w-[30rem] w-[50vw] ">
            {" "}
            <img src={Spocc} alt="ImageNA" className="mt-5 "></img>
          </div>
          <div className="md:w-[10rem] w-[15vw]  md:ml-0">
            <img src={cross} alt="ImageNA" className="mt-5 "></img>
          </div>
          <div className="md:w-[20rem] w-[40vw] mt-5">
            {" "}
            <img src={CCC} className=" scale" alt="ImageNA"></img>
          </div >

          
          
        </div>

        

        <div className="flex flex-col gap-6 mt-16 ml-20  lg:ml-20 text-md md:text-2xl w-fit">
          <div className="flex items-center gap-4  flex-nowrap w-fit">
            <SlCalender /> <p>20 September 2024</p>
          </div>
          <div className="flex items-center gap-4  flex-nowrap w-fit"><FaRegClock /> <p>4pm Onwards</p></div>
          <div className="flex items-center gap-4  flex-nowrap w-fit"><CiLocationOn className="font-bold " /> <p>3rd Floor, CS/IT Block</p></div>
        
        
        </div>
        <div className=" text-[#000] md:mt-20  mt-6 lg:ml-20 ml-20 w-fit ">
              <button
                className=" bg-[#E5B4FF] text-[#000] w-40 h-14 m-30 font-semibold p-3 rounded-md"
                onClick={clickHandler}
              >
                Register now
              </button>
            </div>

            </div>
        <div className="mt-6">
          <Footer />
        </div>

           
         <img className="absolute md:right-[-1.5vw] right-[-1.85rem] top-40" src={green}></img>
         <img className=" absolute md:left-[-1.5vw] left-[-1.2rem]  top-[10.5rem] md:top-40" src={blue}></img>
         <img className="hidden lg:block lg:absolute right-[-3.5vw] scale-50 top-[-8vh] " src={TopCross}></img>
         <img className="hidden lg:block lg:absolute right-[2vw] top-[30rem]" src={second}></img>
         <img className="hidden lg:block lg:absolute left-[50vw] scale-50 top-[25rem]" src={third}></img>
         <img className="hidden lg:block lg:absolute left-[30vw] top-[28rem]" src={fourth}></img>
         <img className="hidden lg:block lg:absolute left-[1vw] top-[25rem]" src={fifth}></img>
      </div>
    </div>
  );
};

export default Home;