
import React, { useEffect } from 'react'
import Footer from '../Components/Footer'
import Card from '../Components/Card'
import About_Design from '../assets/AboutPage_design.svg'
import RegisterPage from '../assets/RegisterPage.svg'
import { FaCheck } from 'react-icons/fa'
import { CiCircleCheck } from 'react-icons/ci'
import { FaUser } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaLock } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa';

import './Pages.css'
import { useLocation } from 'react-router-dom'
import axios from "axios";

const Register = () => {
  // const location = useLocation();
  // const { state } = location;
  // const formData = state?.formData;
  // console.log(formData.email_id)
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.post('https://registrationportal-hrxz.onrender.com/form/mailer/',
  //         {
  //           "email": formData.email_id
  //         });
  //       // if (!response.ok) {
  //       //   throw new Error('Network response was not ok');
  //       // }
  //       const result = await response.json();
  //       // Perform side effect with the result
  //       // console.log('API data:', result);
  //     } catch (err) {
  //       // console.error('Error fetching data:', err);
  //     }
  //   };

  //   fetchData();
  // }, []);
  return (
    // <div>
    //    <div className='Header'></div>
    //    <div className='register-content'>
    //     <Card />
    //     <div className="WhiteCard_Register">
    //       <img src={About_Design} alt="Rohit" className="Left_design"/>
    //       <img src={About_Design} alt="Rohit" className="Right_design"/>
    //       <img src={RegisterPage} alt="Rohit" className="Register_design"/>
    //       <form action='/register/mainregister'>
    //        <button className='button_register bg-[black] rounded-[10px] absolute left-[36.5%] top-[70%] w-[300px] h-[64px] font-poppins text-white font-normal text-2xl'>Register Now!</button>
    //       </form>
    //     </div>
    //     </div>
    //    <div className='footer_facultypage'><Footer /></div>
    // </div>
    <div className="flex flex-col mt-11">
      <div className=" w-full rounded-lg h-fit pb-11 px-4 bg-[#231F4F] bg-opacity-70 ">
        <h1 className="pt-5 m-2 text-5xl font-normal text-center text-white h1_name_RegisterPage">
          Registration Closed
        </h1>
        <div className=" flex flex-col items-center text-center p-6  gap-6 text-3xl text-[#fff]">
          <p className=' text-8xl text-[#E5B4FF]'><FaLock /></p>
          <p className="w-full mx-auto text-xl md:text-2xl">We're sorry, registrations are now closed.</p>
          <p className="w-full mx-auto text-xl md:text-2xl">The event has also concluded. Thank you for your interest!</p>
        </div>
      </div>
      <div className="">

        <Footer />
      </div>

    </div>
  );
}

export default Register;
