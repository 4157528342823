import React from "react";

function Card() {
  
  return (
    <div>
      <div className="card"></div>
    </div>
  );
}

export default Card;
